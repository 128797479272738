body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}


#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.react-joyride__tooltip {
  padding: 5px !important;
  width: 220px !important;
}

.react-joyride__tooltip > div > div {
  padding: 10px !important;
}

.react-joyride__tooltip > div:nth-child(2) {
  margin-top: 0px !important;
}

.alertify .ajs-modal, .MuiPopover-paper, .MuiModal-root {
  z-index: 99999999999 !important;
}

/* temporário */
.seismic-interpretation-delete {
  opacity: 0;
}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}


/* .react-joyride__tooltip > div:nth-child(2) > button {
  background-color: rgb(4, 31, 60) !important;
} */