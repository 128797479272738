


.intersecting-3d-seismic-data-popup-container {
    width: 100vw;
    height: 100vh;
    position:absolute;
}

.popup-top-container {
    height: 25px;
    background-color: #b3b3b3;
}

.popup-content-container {
    padding: 30px;
}

.intersecting-seismics-list-container {
    position:absolute;
    z-index: 999;
    top: 300px;
    left: 300px;
    background-color: white;
}

.seismics-3d-data-table, .seismics-3d-data-table th, .seismics-3d-data-table td {
    border: 1px solid #cccccc;
    border-collapse: collapse;
}

.seismics-3d-data-table th, .seismics-3d-data-table td {
    padding: 5px;
    font-size: 16px;
}

.search-seismics-form {
    display: flex;
    margin-bottom: 20px;
}

.search-seismics-form select > option {
    border-color: #cccccc;
}

.search-seismics-form select > .label-option {
    display: none;
}





.search-seismics-form .inputs-container {
    display:flex;
    justify-content: space-around;
}


#seismic-list-btn {
    padding: 5px 5px;
    background-color: #8600b3;
    color: white;
    border-radius: 4px;
    border: none;
}

table, tr, th {
    border: '1px solid';
}